import React from 'react'

import SEO from '~/components/seo'
import { graphql, Link } from 'gatsby'
import PageLayout from '../components/PageLayout'

export const query = graphql`
  query NotFoundPageQuery($lang: String) {
    prismicNavigationBar(lang: { eq: $lang }) {
      ...NavigationBarFragment
    }
    prismicLeftNavigationMenu(lang: { eq: $lang }) {
      ...LeftNavigationMenuFragment
    }
    prismicRightNavigationMenu(lang: { eq: $lang }) {
      ...RightNavigationMenuFragment
    }
    prismicStayConnectedSection(lang: { eq: $lang }) {
      ...StayConnectedSectionFragment
    }
    prismicBottomNavigationBar(lang: { eq: $lang }) {
      ...BottomNavigationBarFragment
    }
    prismicCookieBanner(lang: { eq: $lang }) {
      ...CookieBannerFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  return (
    <PageLayout pageData={data}>
      <SEO title="Not Found - Grizzli Winery" />
      <div className="min-h-screen py-general page-general">
        <section className="container mx-auto flex flex-col items-center px-4 md:px-2">
          <h1 className="text-gray-title">Not Found</h1>
          <p className="text-gray-body">
            The page you were looking for does not exist.
          </p>
          <Link to="/" className="btn btn-yellow-filled mt-6">
            GO HOME
          </Link>
        </section>
      </div>
    </PageLayout>
  )
}

export default NotFoundPage
